import { InjectionToken, Type } from '@angular/core';
import { CalendarsService } from '../services/implementations/calendars.service';
import { CalendarServerType } from '../models/calendar-server-type.enum';

export type CALENDAR_SERVICE_LOADER = {
  load: () => Promise<Type<CalendarsService>>;
  type: CalendarServerType;
};

export const CALENDARS_CONFIGURATION: InjectionToken<any> = new InjectionToken<any>(
  '@CUE/CALENDARS-CONFIGURATION',
);
export const CALENDARS_SERVICE_REGISTRATION: InjectionToken<CALENDAR_SERVICE_LOADER> =
  new InjectionToken<CALENDAR_SERVICE_LOADER>('@CUE/CALENDARS-SERVICE-REGISTRATION');
